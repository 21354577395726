<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table stripe pagination max-items="50" search :data="centreHospitaliers">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown  vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="$router.push('/apps/centre_hopitalier/centre_hopitalier-add')">Nouveau centre hospitalier</vs-button>
              </template>
              <template slot="thead">
                <vs-th>
                  N°
                </vs-th>
                <vs-th sort-key="RaisonSoc">
                  Raison Sociale
                </vs-th>
                <vs-th sort-key="adresse.AdrCli">
                  Adresse
                </vs-th>
                <vs-th sort-key="adresse.MailCli">
                  Mail
                </vs-th>
                <vs-th sort-key="adresse.TelCli3">
                  Telephone
                </vs-th>
                <vs-th sort-key="website">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td>
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="tr.RaisonSoc">
                    {{tr.RaisonSoc}}
                  </vs-td>
                  <vs-td :data="tr.adresse.AdrCli">
                    {{tr.adresse.AdrCli}}
                  </vs-td>
                  <vs-td :data="tr.adresse.MailCli">
                    {{tr.adresse.MailCli}}
                  </vs-td>
                  <vs-td :data="tr.adresse.TelCli1">
                    {{tr.adresse.TelCli1}}
                  </vs-td>

                  <vs-td :data="tr._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateCentreHospitalierShowForm(tr)" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      centrehospitaliers:[],
      centrehospitalierId: '',
      idCentre: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    }
  },

  methods: {
    updateCentreHospitalierShowForm (data) {
      this.$router.push(`/apps/centre_hopitalier/centre_hopitalier-edit/${data._id}`).catch((err) => { console.log(err) })
    },
    getCentreHospitalier () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .then(() => {

        })
        .catch((err) => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (data) {
      this.idCentre = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Voulez-vous supprimer le centre hospitalier "${data.RaisonSoc}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('centre_hospitalier/removeCentreHospitalier', this.idCentre)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Suppression',
        text: 'Le centre selectionné a été supprimer avec success'
      })
    }
  },
  created () {
    this.getCentreHospitalier()
  }
}
</script>

